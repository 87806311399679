<template>
<div></div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  created(){
    let currentUrl=window.location.href
    let currentDomain=window.location.hostname
    let currentProtocol=window.location.protocol
    let redirectDomain="oceroblog.metalstudio.top"
    let toPart=currentProtocol+"//"+redirectDomain
    let changedPart=currentProtocol+"//"+currentDomain
    let toUrl=currentUrl.replace(changedPart,toPart)
    window.location.replace(String(toUrl))
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
